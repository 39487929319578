<template>
  <div>
    <div class="py-8 px-0" v-if="!loading">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold">Dashboard</h1>
        <div class="w-3/6 flex gap-3">
          <input
            type="date"
            v-model="filter.start_date"
            onfocus="(this.type='date')"
            onblur="(this.type='text')"
            placeholder="Filter start date"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-white py-2 px-3 rounded-md w-1/3"
          />
          <input
            type="date"
            v-model="filter.end_date"
            onfocus="(this.type='date')"
            onblur="(this.type='text')"
            placeholder="Filter end date"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-white py-2 px-3 rounded-md w-1/3"
          />
          <select
            v-if="branches[0].id"
            v-model="filter.branch"
            placeholder="select branch"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-white py-2 px-3 rounded-md w-1/3"
          >
            <option value="" disabled="true" selected="true">
              Select branch
            </option>
            <option
              v-for="branch in branches"
              :key="branch.id"
              :value="branch.id"
            >
              {{ branch.branch_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="my-5 flex gap-5">
        <div class="bg-white rounded-xl p-5 flex items-center gap-5 w-1/4">
          <div class="bg-blue-300 bg-opacity-30 p-3 rounded-full">
            <heart-icon class="w-5 h-auto" />
          </div>
          <div class="">
            <h3 class="text-2xl font-bold text-gray-700">
              {{ dashboard_items.staff_count || 0 }}
            </h3>
            <p>No of Users</p>
          </div>
        </div>
        <div class="bg-white rounded-xl p-5 flex items-center gap-5 w-1/4">
          <div class="bg-yellow-300 bg-opacity-30 p-3 rounded-full">
            <game-icon class="w-5 h-auto" />
          </div>
          <div class="">
            <h3 class="text-2xl font-bold text-gray-700">
              {{ dashboard_items.corpse_count || 0 }}
            </h3>
            <p>Number of Corpse</p>
          </div>
        </div>
        <div class="bg-white rounded-xl p-5 flex items-center gap-5 w-1/4">
          <div class="bg-red-300 bg-opacity-30 p-3 rounded-full">
            <bag-icon class="w-5 h-auto" />
          </div>
          <div class="">
            <h3 class="text-2xl font-bold text-gray-700">
              {{ dashboard_items.enquiry_count || 0 }}
            </h3>
            <p>Number of Enquiries Logged</p>
          </div>
        </div>
        <div class="bg-white rounded-xl p-5 flex items-center gap-5 w-1/4">
          <div class="bg-primary bg-opacity-30 p-3 rounded-full">
            <work-icon class="w-5 h-auto" />
          </div>
          <div class="">
            <h3 class="text-2xl font-bold text-gray-700">
              {{ dashboard_items.corpse_release_count || 0 }}
            </h3>
            <p>Number of bodies Released</p>
          </div>
        </div>
      </div>
      <div class="w-full bg-white p-3 rounded-lg shadow-md">
        <h2 class="font-bold text-2xl">Reports</h2>
        <apexchart
          :series="series"
          id="vuechart-example"
          :options="chartOptions"
          type="area"
        />
      </div>
      <div class="" v-if="corpses.length !== 0">
        <addmission-table
          :allCorpse="corpses"
          :perPage="corpsePerPage"
          :currentPage="currentPage"
          :total="totalCorpse"
          :actions="false"
        />
      </div>
    </div>
    <div class="h-full w-full flex items-center justify-center" v-else>
      <Spinner />
    </div>
  </div>
</template>

<script>
import { fetchBranches } from "@/services/branch";
import { executive, dashboard, accounting } from "@/services/dashboard";
import { fetchcorpse } from "@/services/addmission";

import "@/plugins/apex-charts";

export default {
  name: "Dashboard",
  data() {
    return {
      loading: false,
      role: [],
      filter: {
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        branch: "",
      },
      dashboard_items: [],
      corpses: [],
      chartOptions: {
        chart: {
          id: "vuechart-example",
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler();
            },
          },
        },
        xaxis: {
          categories: [],
          // categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: "series-1",
          data: [],
          // data: [30, 40, 35, 50, 49, 60, 70, 91],
        },
      ],
      donut: {
        options: {
          legend: {
            position: "bottom",
          },
          chart: {
            id: "donut",
            events: {
              mounted: (chart) => {
                chart.windowResizeHandler();
              },
            },
          },
        },
        series: [44, 55, 12, 34],
      },
    };
  },
  components: {
    HeartIcon: () => import("@/assets/icons/HeartIcon"),
    GameIcon: () => import("@/assets/icons/GameIcon"),
    BagIcon: () => import("@/assets/icons/BagIcon"),
    WorkIcon: () => import("@/assets/icons/WorkIcon"),
    AddmissionTable: () =>
      import("@/components/Addmission/AddmissionTable.vue"),
    Spinner: () => import("@/components/Base/Spinner.vue"),
  },
  computed: {
    roles() {
      return this.$store.state.roles;
    },
    userBranch() {
      return this.$store.state.userDetail.branch;
    },
    userRole() {
      return this.$store.state.userDetail.staffroles[0].user_type_name;
    },
    branches() {
      return this.$store.state.branches;
    },
  },
  methods: {
    async fetchAllBranches() {
      try {
        const response = await fetchBranches();
        this.$store.commit("SET_BRANCHES", response.data.branch.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDashboard() {
      this.loading = true;
      try {
        if (this.userRole !== "Executive Admin") {
          this.filter.branch = this.userBranch.id;
        }
        const response = await executive(this.filter);
        this.dashboard_items = response.data.data;
      } catch (error) {
        this.$toast.error(error);
        console.log(error);
      }
      this.loading = false;
    },
    async fetchAllCorpse(page = 1) {
      this.sideModal = false;
      this.overlay = false;
      this.editCorpseDetail = {};
      this.push = 1;
      try {
        const response = await fetchcorpse(page);
        this.corpses = response.data.corpse.data;
        this.corpsePerPage = response.data.corpse.per_page;
        this.totalCorpse = response.data.corpse.total;
        this.currentPage = response.data.corpse.current_page;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
    async fetchData() {
      try {
        const respond = await dashboard();
        respond.data.chart_data.forEach((item) => {
          this.chartOptions.xaxis.categories.push(item.x);
          this.series[0].data.push(item);
        });
        const account = await accounting(this.filter.start_date);
        console.log(account.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    filter: {
      handler() {
        this.fetchDashboard();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchAllBranches();
    this.fetchDashboard();
    this.fetchAllCorpse();
    this.fetchData();
  },
};
</script>
